

.match--card{
  @apply w-80-v h-44 bg-background flex flex-col text-light-black p-4 rounded-lg my-1.5 md:max-w-80 gap-2 ;
}

.match--card-border{
 @apply shadow-live-match;
}

.match--date,
.match--live
{
 @apply text-gray4 text-xs font-archivo;
}

.match--live{
  @apply flex gap-2;
}

.match--live-minute
{
  @apply text-xs text-cm animate-pulse font-archivo text-center;
}

.match--team{
  @apply flex justify-start flex-row text-lg items-center whitespace-nowrap;
}

.match--results{
  @apply flex flex-col bg-color-6 rounded px-2 py-1.5;
}

.match--result{
  @apply font-anton text-lg text-alternate w-5 h-7 text-center;
}

.match--footer{
  @apply flex text-gray4 text-xs font-archivo justify-between border-t-color-7 border-t pt-2 items-center;
}