.headline{
  @apply table bg-color-4 text-cm text-base mr-auto px-2 rounded font-archivo overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}

.headline-green{
  @apply table bg-cm-2 text-white text-base mr-auto px-2 rounded font-archivo overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}

.headline-alternate{
  @apply table bg-color-5 text-cm text-base mr-auto px-2 rounded font-archivo overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}